<template>
  <div>
    <v-card flat class="pa-3 mt-2">
      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-toolbar dense dark color="primary">
              <v-toolbar-title><h4 class="font-weight-light">Transmittal OR</h4>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-col md="4" cols="12"></v-col>
              <!--              <v-row>-->
              <!--                <v-col md="4" cols="12">-->
              <!--                  <v-text-field-->
              <!--                    v-model="transmittal_no"-->
              <!--                    label="Transmital #"-->
              <!--                    dense-->
              <!--                    outlined-->
              <!--                    readonly-->
              <!--                  ></v-text-field>-->
              <!--                </v-col>-->
              <!--              </v-row>-->
              <v-row>
                <v-col md="4" cols="12">
                  <v-select
                    v-model="or_address"
                    dense
                    outlined
                    label="OR Address"
                    item-value="id"
                    item-text="branch_code"
                    :items="available_or_items"
                    :rules="rules.combobox_rule"
                    @change="selected_or_address"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="4" cols="12">
                  <v-text-field
                    v-model="stab_from"
                    label="OR Stab from"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col md="4" cols="12">
                  <v-text-field
                    v-model="stab_to"
                    label="OR Stab to"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="4" cols="12">
                  <v-select
                    v-model="series_from"
                    dense
                    outlined
                    label="OR Series from"
                    :items="available_or_from"
                    :rules="rules.combobox_rule"
                    item-value="id"
                    item-text="series"
                    @change="selected_or_from"
                  ></v-select>
                </v-col>
                <v-col md="4" cols="12">
                  <v-select
                    v-model="series_to"
                    dense
                    outlined
                    label="OR Series to"
                    :items="available_or_to"
                    :rules="rules.combobox_rule"
                    item-value="id"
                    item-text="series"
                    @change="selected_or_to"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="6" cols="12">
                  <v-text-field
                    v-model="released_to"
                    label="Released To"
                    dense
                    outlined
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert color="warning" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{ alert_message }}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-btn color="primary" class="me-3 mt-4" @click="save_caller" v-if="!saving"> Save
                changes
              </v-btn>
              <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                   v-else></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-text class="mt-4">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-uppercase">ID</th>
              <th class="text-center text-uppercase">Batch #</th>
              <th class="text-center text-uppercase">Stab #</th>
              <th class="text-center text-uppercase">OR Series</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in list_of_branch" :key="item.id">
              <td>{{ item.id }}</td>
              <td class="text-center">
                {{ item.transmittal_no }}
              </td>
              <td class="text-center">
                {{ item.stab_from+' - '+item.stab_to}}
              </td>
              <td class="text-center">
                {{ item.series_from+' - '+item.series_to}}
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {
    mdiAlertOutline,
    mdiCloudUploadOutline,
    mdiAccountSearchOutline,
    mdiPencilBoxOutline
  } from '@mdi/js'
  import {mapGetters, mapActions} from 'vuex'
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      cropped: require('@/assets/images/avatars/2.png'),

      saving: false,
      alert: false,
      is_kaagapay: false,
      alert_message: '',

      transmittal_no: '',
      or_address: '',
      available_or_items: [],
      available_or_from: [],
      available_or_to: [],
      stab_from: '',
      stab_to: '',
      series_from: '',
      series_to: '',
      released_to: '',


      list_of_branch: [],
      is_deleting: false,
    }
  }

  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        show: false,
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiAccountSearchOutline,
          mdiPencilBoxOutline,
        },
      }
    },
    mounted() {
      this.list_of_branches()
    },
    data() {
      return initialState()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', ['branch', 'branch_id', 'year', 'month_start', 'month_end', 'month_of', 'user_id']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('transmital_or', ['assign_to_branch_transmital_or', 'initialize_transmital_or']),
      list_of_branches() {
        this.initialize_transmital_or({
          branch_id: this.branch_id,
        })
          .then(response => {
            this.available_or_items = response.data
            this.transmittal_no = this.list_of_branch.length + 1
          })
          .catch(error => {
            console.log(error)
          })
      },
      auto_generation() {
        this.series_to = 0 + ''
        this.series_to = Math.floor(((parseFloat(this.stab_to) - parseFloat(this.stab_from)) + 1) * (parseFloat(this.series_from) + 49)) + ''
      },
      reset() {
        Object.assign(this.$data, initialState())
        this.list_of_branches()
      },
      selected_or_address() {
        var index = this.available_or_items.map(function (x) {
          return x.id;
        }).indexOf(this.or_address)
        if (index != -1) {
          this.available_or_from = this.available_or_items[index].or_series
          this.available_or_to = this.available_or_items[index].or_series
        }
      },
      selected_or_from() {
        var index = this.available_or_from.map(function (x) {
          return x.id;
        }).indexOf(this.series_from)
        if (index != -1) {
          this.stab_from = this.available_or_from[index].stab_no
        }
      },
      selected_or_to() {
        var index = this.available_or_to.map(function (x) {
          return x.id;
        }).indexOf(this.series_to)
        if (index != -1) {
          this.stab_to = this.available_or_to[index].stab_no
        }
      },
      save_caller() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          if (this.branch_id === 1) {
            this.alert = true
            this.alert_message = 'Please select Branch First!!!'
            this.saving = false
          } else {
            const data = new FormData()
            data.append('branch_id', this.branch_id)
            data.append('stab_from_id', this.series_from)
            data.append('stab_to_id', this.series_to)
            data.append('released_to', this.released_to.toUpperCase())
            this.assign_to_branch_transmital_or(data)
              .then(response => {
                var color = 'success'
                if (response.status === 201) {
                  color = 'error'
                } else {
                  this.reset()
                }
                this.change_snackbar({
                  show: true,
                  color: color,
                  text: response.data,
                })
              })
              .catch(error => {
                console.log(error)
                this.alert = true
                this.alert_message = error.response.data.message
                this.saving = false
              })
          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
    },
  }
</script>
